
import { defineComponent } from "vue";
import moment from "moment";
const _ = require("lodash")

export default defineComponent({
  name: "ChartCreator",
  components: { },
  data(){
      return {
        src : "",
        resultSrc:"",
        chartType :  "line",
        aggregation : "none",
        sensorId: "",  
        style : "height :700px; width:100%;" ,
         startDate : "" ,
          endDate : ""
      }
  },
  mounted(this:any){
      
       this.$emit('show-header'); 
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate =  moment().add(1,'d').format("YYYY-MM-DD");
        
  },
  methods :{   
  copyURL(){
       try{ 
            window.navigator.clipboard.writeText( this.resultSrc );
            this.$emit('show-flash',{"message":"クリップボードにURLをコピーしました。","type": "success"}); 
         }catch(e){
             console.log(e);
               this.$emit('show-flash',{"message":"お使いのブラウザはクリップボードのショートカットに対応していません。","type": "warning"});  
         }
  },
  getURL (){
      const iframe:any = window.document.getElementById("chartview");
      const url = iframe.contentWindow.location.href ;
      this.resultSrc = url ;
  },
  update (this:any){
      
      switch( this.chartType ){
        
        case "line": {
            var src = window.location.protocol+"//" +window.location.host+ "/app/chart/line/?sensor=" + this.sensorId +"&start="+this.startDate + "&end="+this.endDate ;
            
            if (this.aggregation != 'none'){
                src = src + "&agg="+this.aggregation;
            }
            this.src = src;
        }
      }
  },
   replaceParamToday(str:string){
        const d = str.match(/\+(.+)D/) ;
           if(d===null){
               return moment().format("YYYY-MM-DD");   
           }
          return moment().add(Number(d[1]),'d').format("YYYY-MM-DD");
    },
    replacePathParam( path:string ){
        
        path = path.replace("{START_DATE}", this.startDate );
        path = path.replace("{END_DATE}",this.endDate);   
     
        const params = path.match(/\{(.*?)\}/g);
        console.log(params);
        if(params){
        var  param:any = params.pop();
        while (param){ 
                const str = this.replaceParamToday(param);
                path = path.replace(param,str);
                param = params.pop();
               }
        
         }

        return path;
    }
 }
});
